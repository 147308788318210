import React from 'react';


const ContentRow = ({ children, backgroundColor, Img, padV }) => (
  <div className={`backgroundWrapper${padV ? ' verticalPadding' : ''}`}>
    {Img}
    {
      backgroundColor && (
        <div className="bg" style={{ backgroundColor }}></div>
      )
    }
    <div className="pageWidth">
      {children}
    </div>
  </div>
);

export default ContentRow;