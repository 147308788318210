import React, { useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Img from 'gatsby-image';


const Header = ({ backgroundColor }) => {
  const { t } = useTranslation();
  const [ menuOpen, setMenuOpen ] = useState(false);
  const showMenu = useCallback(() => setMenuOpen(true), []);
  const hideMenu = useCallback(() => setMenuOpen(false), []);
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(
            maxWidth: 1440,
            traceSVG: {
              color: "#ff009b"
            }
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <header style={{ backgroundColor }}>
      <div className="pageWidth">
        <Link to="/" className="logo">
          <Img fluid={data.file.childImageSharp.fluid} alt={t('logoAlt')} />
        </Link>
        <nav className={`mainMenu${menuOpen ? ' show' : ''}`}>
          <div className="menuWrapper">
            <ul className="items">
              <li>
                <Link partiallyActive={true} to="/casino/" onClick={hideMenu}>{t('menuCasinos')}</Link>
                <ul>
                  <li><Link partiallyActive={true} to="/casino/auslots/" onClick={hideMenu}>AuSlots</Link></li>
                  <li><Link partiallyActive={true} to="/casino/luckystar/" onClick={hideMenu}>LuckyStar</Link></li>
                  <li><Link partiallyActive={true} to="/casino/betbtc/" onClick={hideMenu}>BetBTC</Link></li>
                  <li><Link partiallyActive={true} to="/casino/spacelilly/" onClick={hideMenu}>Space Lilly</Link></li>
                  <li><Link partiallyActive={true} to="/casino/emu/" onClick={hideMenu}>Emu Casino</Link></li>
                  <li><Link partiallyActive={true} to="/casino/777slotsbay/" onClick={hideMenu}>777 Slots bay</Link></li>
                  <li><Link partiallyActive={true} to="/casino/777bay/" onClick={hideMenu}>777Bay</Link></li>
                </ul>
              </li>
              <li><Link partiallyActive={true} to="/myneosurf/" onClick={hideMenu}>MyNeosurf</Link></li>
              <li><Link partiallyActive={true} to="/paysafecard/" onClick={hideMenu}>{t('menuPromotions')}</Link></li>
              <li><Link partiallyActive={true} to="/blog/" onClick={hideMenu}>{t('menuBlog')}</Link></li>
            </ul>
            <ul className="langs">
              <li><Link to="/" language="en" onClick={hideMenu}>English</Link></li>
              <li><Link to="/" language="fr" onClick={hideMenu}>Française</Link></li>
            </ul>
          </div>
          <span className="anchor close cursorDefault" aria-label={t('closeMenu')} onClick={hideMenu}>&times;</span>
        </nav>
        <span className="anchor mobileMenuToggle cursorDefault" aria-label={t('openMenu')} onClick={showMenu}>&#9776;</span>
      </div>
    </header>
  );
}

export default Header;
